import React, { useState } from "react";
import { googleMarkerIcon, PopSelectAddressProp } from "./utils";
import { useParams } from "react-router-dom";
import { GoogleMap } from "@react-google-maps/api";
import ErrorModal from "../../../../../shared/components/ErrorModal";
import useCustomerInfo from "../../../../../hooks/useCustomerInfo";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../hooks/useRedux";
import GoogleMapsBox from "../../../../../shared/components/GoogleMapsBox";
import { getCustomerInfoFromStroge, SelectedOulet } from "../../../utils";
import BottomButton from "../../../../../shared/components/BottomButton";
import BackArrow from "../../../../../assets/img/svgs/BackArrow";
import { getDeliveryZonseInfo } from "../../../../../helper/MatchLocation";
import PopDelivery from "../PopDelivery";
const PopSelectAddress: React.FC<PopSelectAddressProp> = (
  props: PopSelectAddressProp
) => {
  const { onPressClose, selectedLatLng, onSaveAddressClose } = props;
  const { bussinessName } = useParams();
  const [showErrorMap, setShowErrorMap] = useState<boolean>(false);
  const { loading, saveNewAddress } = useCustomerInfo();
  const { t } = useTranslation();
  const [location, setLocation] = useState<string>("");
  const location1 = window.navigator && window.navigator.geolocation;
  const customerInfo = getCustomerInfoFromStroge();
  const localData = localStorage.getItem("shop/" + bussinessName);
  const [showError, setShowError] = useState<boolean>(false);
  const [showAddressError, setShowAddress] = useState<boolean>(false);
  const [labelError, setLabelError] = useState<boolean>(false);
  const [deliveryInstructoin, setDeliveryInstruction] = useState<string>("");
  const [locationMode, setLocationMode] = useState<"current" | "custom">(
    "current"
  );
  const [latLong, setLatLong] = useState(selectedLatLng);
  const [refineLocation, setRefineLocation] = useState<boolean>(false);
  const shopInfo = useAppSelector((state) => state.shopInfo);
  const [currentAddress, setCurrentAddress] = useState("");
  const [locationStatus, setLocationStatus] = useState("default");
  const [center, setCenter] =
    // @ts-ignore
    useState<google.maps.LatLngLiteral>();
  const [selectedLable, setSelectedLabel] = useState<string>("");
  const closeAddressModal = () => {
    onPressClose();
  };

  const selectLabelHandler = (labelValue: string) => {
    setSelectedLabel(labelValue);
  };

  const getCurrent_Location = () => {
    setRefineLocation(true);
  };
  const showErrorModalMaps = () => {
    setShowErrorMap(true);
  };
  const closeErrorModalMaps = () => {
    setShowErrorMap(false);
  };
  const checkPermission = () => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      requestLocation(result.state);
      result.onchange = () => {
        requestLocation(result.state);
      };
    });
  };
  const requestLocation = (locationStatus: string) => {
    if (locationStatus === "denied") {
      alert(
        "Location access is denied. Please enable it in your browser settings."
      );
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert(
              "Location access was denied. Please enable it in your browser settings."
            );
          }
        }
      );
    }
  };

  const getDeliveryZonseHandler = async () => {
    var newCenter = latLong !== undefined ? latLong : center;
    var isLocFound = await getDeliveryZonseInfo(
      shopInfo,
      shopInfo.delivery_zones,
      newCenter
    );
    return isLocFound;
  };
  const validation = () => {
    var isValidate = true;
    if (currentAddress.length === 0) {
      setShowAddress(true);
      isValidate = false;
      return isValidate;
    }
    if (selectedLable.length === 0) {
      setLabelError(true);
      isValidate = false;
      return isValidate;
    }
    return isValidate;
  };
  const saveAddressToDb = () => {
    if (localData !== null) {
      var parsedData: any = {};
      var parsedCustomerInfo = customerInfo !== null && customerInfo;
      parsedData = JSON.parse(localData);
      saveNewAddress(
        parsedCustomerInfo.id,
        deliveryInstructoin,
        selectedLable,
        location,
        parsedData?.outletInfo?.selectedLocationName,
        // lat_lng,
        `${`${latLong?.lat},${latLong?.lng}`}`,
        parsedData?.accessToken
      ).then((response) => {
        if (response.success) {
          onPressClose();
          onSaveAddressClose("navigate", response.data);
        } else {
        }
      });
    }
  };
  const saveAddressHandler = async () => {
    if (!validation()) {
      return;
    }
    if (locationMode === "custom") {
      saveAddressToDb();
      return;
    }
    if (locationMode === "current") {
      var locationHandler = await getDeliveryZonseHandler();
      if (!locationHandler.is_found) {
        showErrorModalMaps();
        return;
      }
      if (locationHandler.is_found) {
        saveAddressToDb();
      }
    }
  };
  React.useEffect(() => {
    // checkPermission();
    if (
      selectedLatLng !== undefined &&
      Object.keys(selectedLatLng).length !== 0
    ) {
      setCenter(selectedLatLng);
      // getGeocodeLoc(selectedLatLng);
    } else {
      if (location1) {
        location1.getCurrentPosition(
          (position) => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });

            setLatLong({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }, []);
  return (
    <div id="pop_cart_page5" className="pop_save_address pop_up_maps_main">
      <div className="popup_cart_full popup_from_bottom  popup_slide">
        <div className="content" id="">
          <div className="cart_head_container">
            <div className="container">
              <div className="header_cart_page">
                <div className="cart_heading">
                  {t("address_new_address_title")}
                </div>
                <span
                  onClick={closeAddressModal}
                  className="target_slide glyphicon"
                >
                  <BackArrow />
                </span>
              </div>
            </div>
          </div>
          {/* <!-- content start here --> */}
          <div className="showing_cart_map">
            <div>
              <GoogleMap
                center={center}
                zoom={17}
                mapContainerStyle={{ height: "220px" }}
                mapContainerClassName={"maps_show"}
                options={{
                  draggable: false,
                  gestureHandling: "greedy",
                  zoomControl: false,
                  disableDefaultUI: true,
                  streetViewControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                }}
              >
                <div className="googleMarker" style={{ ...googleMarkerIcon }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="128px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 128 128"
                    width="128px"
                  >
                    <path d="M98.094,37.911c-0.369-1.387-1.12-2.863-1.674-4.157C89.77,17.78,75.246,12.053,63.518,12.053  c-15.702,0-32.994,10.527-35.303,32.229v4.434c0,0.185,0.063,1.846,0.156,2.678c1.294,10.342,9.453,21.334,15.548,31.676  c6.556,11.082,13.361,21.979,20.102,32.877c4.155-7.11,8.297-14.313,12.359-21.242c1.106-2.031,2.393-4.062,3.501-6.003  c0.738-1.291,2.148-2.584,2.794-3.785c6.556-12.005,17.11-24.104,17.11-36.016v-4.896C99.786,42.714,98.187,38.189,98.094,37.911z   M63.804,60.166c-4.616,0-9.666-2.308-12.16-8.68c-0.372-1.015-0.342-3.047-0.342-3.232V45.39c0-8.124,6.899-11.819,12.9-11.819  c7.389,0,13.103,5.91,13.103,13.298C77.305,54.258,71.192,60.166,63.804,60.166z" />
                  </svg>
                </div>
              </GoogleMap>
            </div>
          </div>
          <div className="cart_foot">
            <div className="cart_pin glyphicon glyphicon-map-marker">
              <div className="cart_map_pin_text" onClick={getCurrent_Location}>
                {t("refine_location")}
              </div>
            </div>
            <div className="cart_content_foot">
              <div className="">
                <div className="cart_content_row"></div>
                <div className="cart_content_row">
                  <div className="title_acc_cell_cart">{t("address")}*</div>
                  <textarea
                    value={currentAddress}
                    name="comment_page_8"
                    className={`cart_text_area ${
                      showAddressError ? "border-error" : ""
                    }`}
                    onChange={(e) => {
                      setCurrentAddress(e.target.value);
                      setLocation(e.target.value);
                      setShowAddress(false);
                    }}
                    placeholder={t("address")}
                  ></textarea>
                </div>
                <div className="cart_content_row">
                  <div className="title_acc_cell_cart">
                    {t("delivery_instruction")}
                  </div>
                  <textarea
                    value={deliveryInstructoin}
                    name="comment_page_8"
                    className="cart_text_area"
                    onChange={(e) => setDeliveryInstruction(e.target.value)}
                    placeholder={t("delivery_instruction_placeholder")}
                  ></textarea>
                </div>
                <div className="cart_content_row">
                  <div
                    className="title_acc_cell_cart"
                    style={{ color: labelError ? "red" : "" }}
                  >
                    {t("address_label")}
                  </div>
                  <button
                    className={`cart_foot_button_sm_rev ${
                      selectedLable === "Home" ? "cart_bt_select" : ""
                    }`}
                    onClick={() => selectLabelHandler("Home")}
                  >
                    {t("home")}
                  </button>
                  <button
                    className={`cart_foot_button_sm_rev ${
                      selectedLable === "Office" ? "cart_bt_select" : ""
                    }`}
                    onClick={() => selectLabelHandler("Office")}
                  >
                    {t("office")}
                  </button>
                  <button
                    className={`cart_foot_button_sm_rev ${
                      selectedLable === "Others" ? "cart_bt_select" : ""
                    }`}
                    onClick={() => selectLabelHandler("Others")}
                  >
                    {t("others")}
                  </button>
                </div>
              </div>
            </div>
            {/* <!-- content end here --> */}
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <div className="cart_foot">
        <BottomButton
          titleProp={t("save_address")}
          onPress={saveAddressHandler}
          loading={loading}
          widthProp={375}
        />
      </div>
      {showErrorMap && (
        <ErrorModal
          headerMessage={t("out_of_location_heading")}
          buttonMessage={t("change_location")}
          onPressClose={() => {
            closeErrorModalMaps();
          }}
          onBackDropClick={() => setShowError(false)}
          errorMessage={t("out_of_location")}
        />
      )}
      {showError && (
        <ErrorModal
          headerMessage="We haven't gotten there yet"
          buttonMessage="Change Location"
          onPressClose={() => {
            setShowError(false);
          }}
          errorMessage={t("out_of_location")}
        />
      )}
      {refineLocation && (
        <PopDelivery
          restoData={shopInfo}
          onSelectedOutlet={(selectedOutlet: any) => {
            setRefineLocation(false);
            setCenter({
              lat: selectedOutlet.selectedLatLon.lat,
              lng: selectedOutlet.selectedLatLon.lng,
            });
            setLatLong({
              lat: selectedOutlet.selectedLatLon.lat,
              lng: selectedOutlet.selectedLatLon.lng,
            });
            setLocationMode("custom");
            // onChangeOutlet(selectedOutlet);
          }}
          onPressClose={() => {
            setLocationMode("current");
            setRefineLocation(false);
          }}
        />
      )}
    </div>
  );
};

export default PopSelectAddress;
