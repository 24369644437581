import React from "react";
import SideLogoName from "./SideLogoName";
interface IPageNotFound {
  notFoundReason?: string;
}
const PageNotFound: React.FC<IPageNotFound> = (props: IPageNotFound) => {
  const { notFoundReason } = props;
  const logo = require("../../assets/img/main_logo.png");
  const openUrl = () => {
    window.location.replace("https://dukany.io/");
  };
  return (
    <>
      <div id="homePage" lang="en" className="homePage">
        <div
          className="page-not-found-wrapper landscape"
          // style={{ float: "left" }}
        >
          {/* <div>{window.location.href}</div> */}
          <h2 className="txt-page-not-found">{notFoundReason}</h2>

          {/* <h5 className="txt-page-not-detail">
            {notFoundReason}
          
          </h5> */}
          <div className="return-to-homepage" onClick={openUrl}>
            <div className="error-return-home">Return to home page </div>
          </div>
        </div>
        <SideLogoName logoUrl={logo} bussinessName={"dukany"} />
      </div>
    </>
  );
};

export default PageNotFound;
