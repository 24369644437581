import React, { useState, useRef, useCallback, useEffect } from "react";
// import "../../../styles.css";
import { GoogleMap, Marker } from "@react-google-maps/api";
import ErrorModal from "./ErrorModal";
import {
  getDeliveryZonseInfo,
  getGeocodeLoc,
  selectedOutInfo,
} from "../../helper/MatchLocation";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { googleMarkerIcon } from "../../pages/Home/Components/Popups/CartPages/utils";
import BackArrow from "../../assets/img/svgs/BackArrow";
import IconInfo from "../../assets/img/svgs/IconInfo";
interface GoogleMapBoxProp {
  onPressClose: () => void;
  selectedLatLng: google.maps.LatLngLiteral | undefined;
  onlocationFound: any;
  restoData: any;
}

const GoogleMapsBox: React.FC<GoogleMapBoxProp> = (props: GoogleMapBoxProp) => {
  const {
    onPressClose,
    selectedLatLng,
    // deliveryZonesInfo,
    onlocationFound,
    restoData,
  } = props;
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [currentAddress, setCurrentAddress] = useState("");
  const { bussinessName } = useParams();
  const finalPathName = "/" + bussinessName;
  const [foundOutLet, setFoundOutlet] = useState("");
  const [locDisable, setLocDisable] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isDraggedCenter, setIsDraggedCenter] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = window.navigator && window.navigator.geolocation;
  const mapRef = useRef<google.maps.Map>(null);
  const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const getDeliveryZonseHandler = async () => {
    var newCenter = lat !== 0 ? { lat: lat, lng: long } : center;
    getDeliveryZonseInfo(restoData, restoData.delivery_zones, newCenter).then(
      async ({ is_found, center, foun_name, deliveryZoneData }) => {
        console.log(is_found, center);
        if (is_found) {
          onPressClose();
          onlocationFound(
            await selectedOutInfo(
              foun_name,
              deliveryZoneData,
              await getGeocodeLoc(newCenter!),
              center!,
              restoData,
              finalPathName
            )
          );
        } else {
          // alert("Location not found please select another one");
          setShowError(true);
        }
      }
    );
  };
  const closeModal = () => {
    onPressClose();
  };
  const onGoogleMapCenterChanged = () => {
    if (mapRef.current?.getCenter()) {
      const center = {
        lat: mapRef.current?.getCenter()!.lat(),
        lng: mapRef.current?.getCenter()!.lng(),
      } as google.maps.LatLngLiteral;
      // console.log("center");
      // console.log(center);
      setIsDraggedCenter(true);
      window.setTimeout(function () {
        setIsDraggedCenter(false);
      }, 500);
      if (JSON.stringify(center) !== JSON.stringify(selectedLatLng)) {
        // console.log(mapRef.current?.getCenter()!.lat());
        setLat(mapRef.current?.getCenter()!.lat());
        setLong(mapRef.current?.getCenter()!.lng());
        // setCenter({
        //   lat: mapRef.current?.getCenter()!.lat(),
        //   lng: mapRef.current?.getCenter()!.lng(),
        // });
      }
    }
  };
  const mapAddressHandler = async (selectedLatLng: any) => {
    setCurrentAddress(await getGeocodeLoc(selectedLatLng));
  };
  // @ts-ignore
  const onLoad = useCallback((map: any) => (mapRef.current = map), []);

  const onGoogleMapDragStart = useCallback(
    () => console.log("on google drag"),
    []
  );
  const getCurrent_Location = () => {
    // setCenter(undefined);
    // setShowGoogleMap(true);
    if (location) {
      location.getCurrentPosition(
        (position) => {
          updateLocationHelper(
            position.coords.latitude,
            position.coords.longitude
          );
          // setCenter(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          setLocDisable(true);
          updateLocationHelper(
            parseFloat(restoData?.coordinates.latitude),
            parseFloat(restoData?.coordinates.longitude)
          );
        }
      );
    }
  };
  const updateLocationHelper = (lat: number, lng: number) => {
    setLat(lat);
    setLong(lng);
    setCenter({
      lat: lat,
      lng: lng,
    });
    getGeocodeLoc({
      lat: lat,
      lng: lng,
    });
  };
  React.useEffect(() => {
    // google.maps.event.addListener(mapRef, 'dragstart', function() { alert('map dragged'); } );
    // mapRef.current?.addListener("dragstart",function(){
    //    alert();
    //  });
    if (
      selectedLatLng !== undefined &&
      Object.keys(selectedLatLng).length !== 0
    ) {
      setCenter(selectedLatLng);
      mapAddressHandler(selectedLatLng);
    } else {
      getCurrent_Location();
    }
  }, []);
  React.useEffect(() => {
    //
    window.onpopstate = (e) => {
      // onPressClose()
      closeModal();
    };
    // console.log(selectedLatLng);
  }, []);
  React.useEffect(() => {
    // console.log(currentAddress);
  }, [currentAddress]);
  const tip = `
    <div style="width: 18px; height: 18px; background-color: '#e01414';" >
      <h3>Hello</h3>
    
    </div>
  `;
  return (
    <>
      <div id="pop_current_location_maps" className="pop_up_maps_main">
        <div className="pop_current_location_maps_show popup_from_bottom">
          <div className="content" id="">
            {/* <div className="pop_current_location_maps_show_head">
              <div className="container">
               
                <div className="pop_current_location_maps_show_head_text">
                  {t("select_location")}
                </div>
                <span onClick={onPressClose} className="target_slide glyphicon">
                  <BackArrow />
                </span>
              </div>
            </div> */}
            <div className="cart_head_container">
              <div className="container">
                <div className="header_cart_page">
                  <div className="cart_heading">{t("select_location")}</div>

                  <span
                    onClick={onPressClose}
                    className="target_slide glyphicon"
                  >
                    <BackArrow />
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className={
                  isDraggedCenter
                    ? "overLay after-dragging"
                    : "overLay before-dragging"
                }
              >
                {" "}
                <div
                  className={
                    isDraggedCenter
                      ? "location-tool-tip"
                      : "location-tool-tip active"
                  }
                >
                  {" "}
                  <span className="tooltiptext tooltip-top">
                    {/* {t("move_map_message")}
                    <br /> */}
                    {/* <small> */}
                    {t("move_pin_message")}
                    {/* Move pin to your exact locatoin */}
                    {/* </small> */}
                  </span>
                </div>
              </div>
              {!isToolTipOpen && (
                <div className="googleMarker" style={googleMarkerIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="128px"
                    id="Layer_1"
                    version="1.1"
                    viewBox="0 0 128 128"
                    width="128px"
                  >
                    <path d="M98.094,37.911c-0.369-1.387-1.12-2.863-1.674-4.157C89.77,17.78,75.246,12.053,63.518,12.053  c-15.702,0-32.994,10.527-35.303,32.229v4.434c0,0.185,0.063,1.846,0.156,2.678c1.294,10.342,9.453,21.334,15.548,31.676  c6.556,11.082,13.361,21.979,20.102,32.877c4.155-7.11,8.297-14.313,12.359-21.242c1.106-2.031,2.393-4.062,3.501-6.003  c0.738-1.291,2.148-2.584,2.794-3.785c6.556-12.005,17.11-24.104,17.11-36.016v-4.896C99.786,42.714,98.187,38.189,98.094,37.911z   M63.804,60.166c-4.616,0-9.666-2.308-12.16-8.68c-0.372-1.015-0.342-3.047-0.342-3.232V45.39c0-8.124,6.899-11.819,12.9-11.819  c7.389,0,13.103,5.91,13.103,13.298C77.305,54.258,71.192,60.166,63.804,60.166z" />
                  </svg>
                </div>
              )}

              <GoogleMap
                ref={(map) => mapRef}
                id="google-delivery-map"
                center={center}
                zoom={15}
                mapContainerClassName={"maps_show"}
                onLoad={onLoad}
                onDragStart={onGoogleMapDragStart}
                // onDragEnd={() => alert("Drag end")}
                // onDrag={() => console.log(" On Drag")}
                onCenterChanged={onGoogleMapCenterChanged}
                options={{
                  gestureHandling: "greedy",
                  zoomControl: true,
                  disableDefaultUI: true,
                  streetViewControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  draggable: true,
                  scaleControl: true,
                }}
              />

              <div className="current-location-button">
                {!locDisable ? (
                  <button onClick={getCurrent_Location}>
                    <svg
                      data-v-713df690=""
                      data-v-fd1ce9d8=""
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      data-spec="icon-locate"
                      className="atom-icon"
                    >
                      <path
                        d="M8.333 11a2.667 2.667 0 105.333 0 2.667 2.667 0 00-5.333 0z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M4.333 11a6.667 6.667 0 1013.334 0 6.667 6.667 0 00-13.334 0v0zM11 1v3.333M1 11h3.333M11 21v-3.333M21 11h-3.333"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    {t("user_your_location")}
                  </button>
                ) : (
                  <>
                    <button
                      data-tip={tip}
                      data-tooltip-id="tooltip-location"
                      onClick={() => {
                        // window.open("chrome://settings/");
                      }}
                    >
                      Enable location
                      <span>
                        <IconInfo style={{}} onPress={() => {}} />
                      </span>
                    </button>

                    <Tooltip
                      setIsOpen={(value) => setIsToolTipOpen(value)}
                      style={{
                        width: "90%",
                        zIndex: 3,
                        textAlign: "start",
                      }}
                      id="tooltip-location"
                      place="right"
                    >
                      <div>
                        <ul>
                          <li>
                            <strong>Chrome:</strong>{" "}
                            {
                              "Go to Settings > Privacy and Security > Site Settings > Location"
                            }
                          </li>
                          <li>
                            <strong>Firefox:</strong>{" "}
                            {
                              "Go to Preferences > Privacy & Security > Permissions > Location"
                            }
                          </li>
                          <li>
                            <strong>Safari:</strong>{" "}
                            {"Go to Preferences > Websites > Location"}
                          </li>
                          <li>
                            <strong>Edge:</strong>{" "}
                            {"Go to Settings > Site permissions > Location"}
                          </li>
                        </ul>
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <div className="pop_current_location_maps_show_foot">
              {/* code for display curretn location text will uncomment in future */}
              <div className="container delivery_searched_item_map">
                {/* <div className="delivery_searched_loaction">
                  <div className="glyphicon glyphicon-map-marker bordered_map"></div>
                </div>
                <div className="delivery_searched_loaction_text">
                  <div className="searched_loaction_text_head">
                    {currentAddress !== ""
                      ? currentAddress
                      : "Getting Information"}
                  </div>
                </div> */}
                <div
                  className="delivery_searched_loaction_change"
                  onClick={onPressClose}
                >
                  <div className="current_location_maps_show_foot_change">
                    {t("change_time_location")}
                  </div>
                </div>
              </div>
              {/* code for display curretn location text will uncomment in future */}
              <div className="searched_loaction_foot_button">
                <button
                  className="confirm_current_location"
                  onClick={getDeliveryZonseHandler}
                >
                  <div className="">{t("confirm_location_button")}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        {showError && (
          <ErrorModal
            headerMessage={t("out_of_location_heading")}
            buttonMessage={t("change_location")}
            onPressClose={() => {
              setShowError(false);
              closeModal();
            }}
            onBackDropClick={() => setShowError(false)}
            errorMessage={t("out_of_location")}
          />
        )}
      </div>
    </>
  );
};

export default GoogleMapsBox;
